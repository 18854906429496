import React from "react";
import {
    Box,
    Card,
    CardActionArea,
    CardMedia,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ShopCoupons() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleCardClick = () => {
        navigate("/viewCoupon");
    };

    return (
        <Box>
            <Card
                sx={{
                    height: isMobile ? "100%" : "284px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={handleCardClick}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height={isMobile ? "100%" : "284px"}
                        image="https://www.typecalendar.com/wp-content/uploads/2023/03/Coupon.jpg"
                        alt="green iguana"
                    />
                </CardActionArea>
            </Card>
        </Box>
    );
}
