import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { getCarouselPhoto } from "../../apicalls";

const CarouselProduct = () => {
    const [items,setItems] = useState([]);
    useEffect(() => {
        (async () => {
            const result = await getCarouselPhoto();
            setItems(result.data.items);
            console.log(result.data.items);
        })();
    }, []);

    return (
        <div>
            <Carousel
                autoPlay={true}
                autoFocus
                infiniteLoop
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                showIndicators={true}
                useKeyboardArrows={true}
            >
                {items.map(item => {
                    return (
                        <div key={item.guid}>
                            <img src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${item.image}`} alt="Slide 1" />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default CarouselProduct;
