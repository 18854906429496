import { Box, Typography, Link } from "@mui/material";

export default function ExploreMore() {
    return (
        <Box>
            <Typography variant="h5">Explore More</Typography>
            <Link href="/contact" underline="hover" color="inherit" sx={{display:"block" , margin:1}}>
                Contact Us
            </Link>
            <Link href="/about" underline="hover" color="inherit" sx={{display:"block" , margin:1}}>
                About Us
            </Link>
            <Link href="#" underline="hover" color="inherit" sx={{display:"block" , margin:1}}>
                Terms and Condations
            </Link>
            {/* <Link href="#" underline="hover" color="inherit" sx={{display:"block"}}>
                Link
            </Link>
            <Link href="#" underline="hover" color="inherit" sx={{display:"block"}}>
                Link
            </Link>
            <Link href="#" underline="hover" color="inherit" sx={{display:"block"}}>
                Link
            </Link>
            <Link href="#" underline="hover" color="inherit" sx={{display:"block"}}>
                Link
            </Link> */}
        </Box>
    );
}
