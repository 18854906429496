import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import CarouselProduct from "../Components/HomePage/CarouselProduct";
import Coupons from "../Components/HomePage/Coupons";
import Shop from "../Components/HomePage/Shop";
import Brand from "../Components/HomePage/Brand";
import Category from "../Components/HomePage/Category";
import {
    getJustForHome,
    getNewArrival,
    getTopRate,
    getallbrands,
    getallshops,
} from "../apicalls";
import { useAuth } from "../DetailProvider";
import ShopJust from "../Components/HomePage/ShopJust";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
    const { translate, searchs } = useAuth();
    const [shops, setShops] = useState([]);
    const [shopJusts, setShopJusts] = useState([]);
    const [shopTops, setShopTops] = useState([]);
    const [brands, setBrands] = useState([]);
    const [newArrivals, setNewArrivals] = useState([]);
    const [loading, setLoading] = useState(true);
    const shopstitle = translate.Shops;
    const shopsTop = translate.TPFY;
    // const shopstitle = translate.Shops;
    const jsy = "Just For You";
    const newArr = translate.NewArrival;
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const result = await getallshops();
            setShops(result.data.items);
            setLoading(false);
            // console.log(result);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const resu = await getJustForHome();
            setShopJusts(resu.data.items);
            // console.log(resu.data.items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const resu = await getTopRate();
            setShopTops(resu.data.items);
            // console.log(resu.data.items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const resu = await getNewArrival();
            setNewArrivals(resu.data.items);
            // console.log(resu.data.items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const resu = await getallbrands();
            setBrands(resu.data.items);
            // console.log(resu.data.items);
        })();
    }, []);

    return (
        <>
            <Box>
                <Box>
                    {searchs && (
                        <Box sx={{ position: "sticky" }}>
                            {searchs.map(res => {
                                return (
                                    <List sx={{ bgcolor: "white", p: 0 }}>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(
                                                        `/viewproductcategory/${res.guid}`
                                                    );
                                                }}
                                            >
                                                <ListItemText
                                                    primary={res.name}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                );
                            })}
                        </Box>
                    )}
                    <CarouselProduct />
                    <Box sx={{ p: "30px 50px 20px 50px" }}>
                        <Coupons />
                        <Shop
                            loading={loading}
                            shops={shops}
                            shopstitle={shopstitle}
                            showView={true}
                        />
                        <Brand brands={brands} />
                        <Category />
                        <ShopJust
                            loading={loading}
                            shops={shopJusts}
                            shopstitle={jsy}
                            showView={false}
                        />
                        <ShopJust
                            loading={loading}
                            shops={shopTops}
                            shopstitle={shopsTop}
                            showView={false}
                        />
                        <ShopJust
                            loading={loading}
                            shops={newArrivals}
                            shopstitle={newArr}
                            showView={false}
                        />
                        {/* shopTops */}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default HomePage;
