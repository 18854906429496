import {
    Avatar,
    Box,
    Button,
    Card,
    CardMedia,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { useEffect, useState } from "react";
import { useCart } from "../../Contexts/CartContext";
import { getCurrency } from "../../apicalls";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ShoppingCartCard = ({ checkboxes, handleCheckboxChange, product }) => {
    let cartQty = parseInt(product.cartQty);
    const [qty, setQty] = useState(cartQty);

    const { cart, setCart } = useCart();
    const cartData = JSON.parse(localStorage.getItem("cart"));
    const removeCartItem = guid => {
        const storedCart = localStorage.getItem("cart")
            ? JSON.parse(localStorage.getItem("cart"))
            : null;
        const deleteProduct = storedCart?.filter(item => item.guid !== guid);
        const newCart = localStorage.setItem(
            "cart",
            JSON.stringify(deleteProduct)
        );
        setCart(newCart);
        window.location.reload();
    };

    const productPrice = product.priceSell * product.rate;

    const [useCurrency, setUseCurrency] = React.useState({});

    const navigate = useNavigate();

    // console.log(product)

    const imgUrl =
        "https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download";

    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result?.data?.item[0]);
            // console.log(result.data.item[0]);
            // console.log(product);
        })();
    }, [product, cartData]);

    // console.log(useCurrency.rate);

    return (
        <Box>
            {/* <CartShopName
                shop={cartData[0]?.shopName}
                photo={cartData[0]?.shopLogo}
                checkboxes={checkboxes}
                handleCheckboxChange={handleCheckboxChange}
            /> */}
            <Card sx={{ marginBottom: "10px" }}>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 24px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            fullWidth
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    sx={{
                                        width: "60px",
                                        height: "60px",
                                    }}
                                    src={`${imgUrl}/${product.shopLogo}`}
                                />
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        marginX: "10px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {product.shopName}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider variant="middle" />
                </>
                <Box
                    key={product?.guid}
                    sx={{
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        gap: "24px",
                        padding: "16px 24px",
                    }}
                >
                    {/* Checkbox */}
                    {/* <FormControlLabel
                    control={
                        <Checkbox
                            name="checkbox1"
                            checked={checkboxes.checkbox1}
                            onChange={handleCheckboxChange}
                        />
                    }
                /> */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // gap: "150px",
                            gap: { md: "150px", sm: "24px", xs: "5px" },
                        }}
                    >
                        {/* Image */}
                        <Card
                            sx={{ width: "200px", height: "200px" }}
                            onClick={() => {
                                navigate(
                                    `/oneproductdetail/${product.guid}/${product.guid}`
                                );
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={`${imgUrl}/${
                                    product.images ? product.images[0] : ""
                                }`}
                                alt="Product Image"
                            />
                        </Card>
                        {/* Description */}
                        <Box
                            onClick={() => {
                                navigate(
                                    `/oneproductdetail/${product.shopGuid}/${product.pguid}`
                                );
                            }}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                            }}
                        >
                            <Typography variant="h6" sx={{ cursor: "pointer" }}>
                                {product.productName}
                            </Typography>
                            <Typography>
                                {product.name}
                                {product.weight
                                    ? ` / ${product.weight} Kg`
                                    : ""}
                            </Typography>
                            <Typography>
                                {productPrice.toLocaleString()} Ks
                            </Typography>
                            <Typography variant="body2" color={"secondary"}>
                                (Approximately{" "}
                                {(
                                    (product.priceSell * product.rate) /
                                    useCurrency.rate
                                ).toFixed(2)}{" "}
                                $)
                            </Typography>
                            {product?.addons[0] && (
                                <>
                                    <Divider />
                                    <Typography
                                        variant="h6"
                                        color={"secondary"}
                                    >
                                        Add ons
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {product?.addons[0]?.name}
                                    </Typography>
                                    <Typography>
                                        {product?.addons[0]?.value?.toLocaleString()}{" "}
                                        Ks
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color={"secondary"}
                                    >
                                        (Approximately{" "}
                                        {(
                                            (product?.addons[0]?.value *
                                                product.rate) /
                                            useCurrency.rate
                                        ).toFixed(2)}{" "}
                                        $)
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            {/* Favorite and Delete Icons */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    gap: "16px",
                                }}
                            >
                                <IconButton
                                    sx={{
                                        alignItems: "initial",
                                        height: "40px",
                                    }}
                                >
                                    <FavoriteBorderIcon />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        alignItems: "initial",
                                        height: "40px",
                                    }}
                                    onClick={() => removeCartItem(product.guid)}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Box>
                            {/* stepper */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        setQty(qty - 1);
                                        // setCart((product.cartQty = qty));
                                        var item = cartData.find(
                                            data => data.guid === product.guid
                                        );
                                        if (item) {
                                            item.cartQty = item.cartQty - 1;
                                        }
                                        localStorage.setItem(
                                            "cart",
                                            JSON.stringify(cartData)
                                        );
                                        // console.log(cartData);
                                        window.location.reload();
                                    }}
                                    disabled={qty <= 1}
                                >
                                    <RemoveIcon />
                                </Button>
                                <Typography variant="body2">{qty}</Typography>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        setQty(qty + 1);
                                        var item = cartData.find(
                                            data => data.guid === product.guid
                                        );
                                        if (item) {
                                            item.cartQty = item.cartQty + 1;
                                        }
                                        localStorage.setItem(
                                            "cart",
                                            JSON.stringify(cartData)
                                        );
                                        // console.log(cartData);
                                        window.location.reload();
                                    }}
                                    disabled={qty >= product.stockQty}
                                >
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default ShoppingCartCard;
