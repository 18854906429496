import {
    Box,
    Drawer,
    Avatar,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import {
    Home as HomeIcon,
    AccountCircle as AccountCircleIcon,
    Mail as MailIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "./DetailProvider";
import Person2Icon from "@mui/icons-material/Person2";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export default function MainDrawer({ showDrawer, toggleDrawer }) {
    const { auth, authUser } = useAuth();
    const storedAuth = localStorage.getItem("auth");
    const storedAuthUser = localStorage.getItem("authUser");
    const { translate } = useAuth();
    const navigate = useNavigate();
    return (
        <div>
            <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer()}>
                <Box sx={{ width: 300 }}>
                    <Typography
                        sx={{
                            alignItems: "center",
                            mt: 3,
                            mx: 4,
                            fontSize: "30px",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        MinThiLa
                    </Typography>
                    {/* <Box
                        sx={{
                            display: "flex",
                            alignItems: "end",
                            height: 250,
                            background: "pink",
                        }}
                    >
                        <Avatar sx={{ width: 98, height: 98, ml: 3, mb: -5 }}>
                            A
                        </Avatar>
                    </Box> */}
                    <List sx={{ mt: 1 }}>
                        {!storedAuth && (
                            <Box>
                                <ListItem>
                                    <Link
                                        to="/register"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                            width: "100%",
                                        }}
                                        onClick={toggleDrawer()}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Register" />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <Link
                                        to="/login"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                            width: "100%",
                                        }}
                                        onClick={toggleDrawer()}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <LoginIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={translate.Login}
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            </Box>
                        )}
                        <Box sx={{display:"flex",alignItems:"center",mx:3}}>
                            <IconButton sx={{mr:3}}>
                                <LoginIcon />
                            </IconButton>
                            <a
                                href="/images/Presentation1 up11111.10.2023.pdf"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    fontSize: "20px",
                                    marginRight: "20px",
                                }}
                            >
                                <ListItemText primary="Customer Care" />
                            </a>
                        </Box>
                        {storedAuth && (
                            <Box>
                                <ListItem>
                                    <Link
                                        to="/add-to-cart"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            toggleDrawer();
                                        }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ShoppingCartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cart Lists" />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <Link
                                        to="/my-account"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                            width: "100%",
                                        }}
                                        // onClick={toggleDrawer()}
                                        onClick={() => {
                                            toggleDrawer();
                                        }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <Person2Icon />
                                            </ListItemIcon>
                                            <ListItemText primary="My Profile" />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <Link
                                        to="/login"
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            localStorage.clear();
                                            toggleDrawer();
                                        }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ShoppingCartIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={translate.Logout}
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            </Box>
                        )}
                    </List>
                </Box>
            </Drawer>
        </div>
    );
}
