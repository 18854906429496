import { Box, Grid, Typography } from "@mui/material";
import ShopCoupons from "./ShopCoupons";
import ShopsProducts from "./ShopsProducts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    getMostPopularProducts,
    getNewArrivalProducts,
    getTopPickProducts,
} from "../../apicalls";
import { useAuth } from "../../DetailProvider";

export default function ShopHome() {
    const mostPopular = "Most Popular";
    const newArrival = "New Arrival";
    const topPick = "Top Pick For You";
    const { guid } = useParams();
    const [mostPopularProducts, setMostPopularProducts] = useState([]);
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [topPickProducts, setTopPickProducts] = useState([]);
    // const [loadingPopular, setLoadingPopular] = useState(true);
    // const [loadingArrival, setLoadingArrival] = useState(true);
    // const [loadingTop, setLoadingTop] = useState(true);
    const [loading, setLoading] = useState(true);
    const { translate, setTranslate } = useAuth();
    useEffect(() => {
        (async () => {
            const result = await getMostPopularProducts(guid);
            setMostPopularProducts(result.data.items);
            // setLoadingPopular(false);
            console.log(result.data.items);
        })();
        (async () => {
            const result = await getNewArrivalProducts(guid);
            setNewArrivalProducts(result.data.items);
            console.log(result.data.items);
            // setLoadingArrival(false);
            // console.log(result);
        })();
        (async () => {
            const result = await getTopPickProducts(guid);
            setTopPickProducts(result.data.items);
            // setLoadingTop(false);
            // console.log(result);
        })();
        setLoading(false);
        // eslint-disable-next-line
    }, [guid]);

    // useEffect(() => {
    //     (async () => {
    //         const result = await getNewArrivalProducts(guid);
    //         setNewArrivalProducts(result.data.items);
    //         setLoadingArrival(false);
    //         console.log(result);
    //     })();
    // }, [guid]);

    // useEffect(() => {
    //     (async () => {
    //         const result = await getTopPickProducts(guid);
    //         setTopPickProducts(result.data.items);
    //         setLoadingTop(false);
    //         console.log(result);
    //     })();
    // }, [guid]);

    return (
        !loading && (
            <Box>
                <ShopCoupons />

                <Typography
                    sx={{
                        paddingTop: "30px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                    }}
                    variant="h5"
                >
                    {translate.MostPopular}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} xs={12}>
                            {mostPopularProducts.map(product => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={product.guid}
                                >
                                    <ShopsProducts product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>

                <Typography
                    sx={{
                        paddingTop: "30px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                    }}
                    variant="h5"
                >
                    {translate.NewArrival}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} xs={12}>
                            {newArrivalProducts.map(product => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={product.guid}
                                >
                                    <ShopsProducts product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>

                <Typography
                    sx={{
                        paddingTop: "30px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                    }}
                    variant="h5"
                >
                    {translate.TPFY}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} xs={12}>
                            {topPickProducts.map(product => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={product.guid}
                                >
                                    <ShopsProducts product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    );
}
