import { Avatar, Box, Card, CardHeader, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Brand = ({ brands }) => {
    const settings = {
        dots: false,
        infinite: false,
        variableWidth:true,
        speed: 500,
        slidesToShow: 6.2,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3.2,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Box sx={{ p: "50px 0px 0px 0px" }}>
            <Typography variant="h4" sx={{ pb: 2 }}>
                Brands
            </Typography>
            <div>
                <Slider {...settings}>
                    {brands.map(brand => {
                        return (
                            <Box key={brand.guid}>
                                <Card
                                    sx={{ maxWidth: 200, borderRadius: "15px" , padding:2,margin:2}}
                                >
                                    <Typography>
                                    {brand.name}
                                    </Typography>
                                    {/* <CardHeader
                                        // avatar={
                                        //     <Avatar
                                        //         alt="Remy Sharp"
                                        //         src={brand.image}
                                        //     />
                                        // }
                                        title={brand.name}
                                    /> */}
                                </Card>
                            </Box>
                        );
                    })}
                </Slider>
            </div>
        </Box>
    );
};

export default Brand;
