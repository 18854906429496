import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { api, getAllProductFromShop } from "../../apicalls";

export default function AllProductFilter() {
    const { guid } = useParams();
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const result = await getAllProductFromShop(guid);
                setShops(result.data.items);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        })();
    }, [guid]);

    return (
        <Box>
            {/* <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt vehicula libero, id aliquet nisl fringilla id. Ut pellentesque, quam at gravida congue, lectus libero rhoncus ligula, eget venenatis sapien quam non massa. Quisque nec metus sed tortor venenatis scelerisque. Suspendisse potenti. Integer aliquet erat a turpis placerat dignissim. Quisque consequat metus id velit tincidunt convallis. Sed suscipit semper bibendum. Nulla facilisi. Fusce eu sollicitudin urna. Maecenas cursus nunc non volutpat congue. Integer vel ligula id ex vehicula tincidunt. Praesent laoreet, purus non bibendum venenatis, erat odio faucibus justo, vel ultrices nisi libero at mi. Sed eu felis eu felis congue ultrices ut at mauris. Sed eu purus ligula.

Suspendisse potenti. Maecenas dictum, sapien at laoreet elementum, mi arcu blandit metus, ut lacinia lectus est eu dui. Nulla facilisi. Sed sed luctus arcu, non finibus neque. Suspendisse potenti. Sed sit amet aliquam felis. Curabitur eget lacus vitae nunc malesuada blandit. Nullam nec dolor nec urna dignissim ultrices. Nulla facilisi. Curabitur consectetur lectus id tortor aliquet, non accumsan justo volutpat. Sed pellentesque, arcu eget congue varius, metus libero pellentesque nisi, ac malesuada turpis velit in odio.

Sed consectetur lectus ac augue iaculis, eget vulputate ex viverra. Quisque sollicitudin ut erat eu interdum. Quisque nec tellus at nulla sollicitudin congue. Vestibulum dapibus nunc eget quam euismod, at hendrerit metus malesuada. Vivamus sagittis, urna id dapibus lacinia, sapien dui iaculis tortor, a tincidunt quam tellus nec ligula. Sed facilisis, arcu quis facilisis facilisis, orci sapien mattis nunc, non aliquet libero ligula in quam. Fusce hendrerit id ligula quis tincidunt. Nunc pellentesque interdum lacinia. Aliquam consequat diam a tortor ullamcorper, non fringilla ligula pharetra. Integer vel purus ultricies, venenatis ante ac, condimentum dolor. Integer auctor, metus id lacinia malesuada, urna tortor bibendum nisi, ut suscipit tortor risus vel risus. Vivamus bibendum orci ac urna vehicula interdum. Etiam id gravida justo. Sed efficitur, odio ut maximus rhoncus, justo turpis bibendum enim, et auctor felis purus ac nisi. Phasellus vehicula massa velit, eu volutpat tortor facilisis vel. Sed in dui in eros lacinia euismod eget eget odio.

Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer ac arcu elit. Nam eget rhoncus neque. Cras dignissim felis ac urna commodo fringilla. Quisque in elementum elit, a sollicitudin arcu. Nullam gravida, leo sit amet vestibulum eleifend, est lorem blandit mauris, in dictum nisl nulla sed velit. Fusce in semper purus. Sed aliquam, dui eget gravida consectetur, arcu turpis vestibulum mi, ut egestas libero arcu a elit. Donec sed orci eu ex feugiat tempus. Nam nec lacinia purus. Aenean scelerisque felis vitae mi malesuada, at tempus tortor interdum.

Proin viverra dui non purus bibendum, in volutpat arcu tristique. Nulla id ipsum in tellus hendrerit fermentum. Nam eget leo mi. Duis non ante eget erat vehicula hendrerit vel at purus. Nunc vitae malesuada elit. Vivamus ac justo in est tincidunt auctor. Vivamus laoreet, libero a posuere tincidunt, felis risus consequat odio, vel posuere libero elit nec orci. Sed dignissim tellus vel urna rhoncus hendrerit. Sed auctor auctor neque, eu volutpat nulla tincidunt sit amet. Duis tincidunt sapien in purus elementum, sit amet tincidunt tellus convallis. In at nisl facilisis, posuere tellus in, finibus turpis. Vivamus ac eros at nunc volutpat mattis. Sed gravida sapien eu eros imperdiet, at auctor libero posuere. Vestibulum tristique vehicula turpis non facilisis. Duis sed mi vel arcu ullamcorper dignissim at nec tellus. In hac habitasse platea dictumst.

I hope this lengthy Lorem Ipsum</Typography> */}
            <Grid container spacing={2}>
                {shops.map(shop => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={shop.guid}>
                        <Card
                            sx={{
                                display: "flex",
                                marginRight: "100px",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                                minWidth: 250,
                            }}
                            onClick={() => {
                                navigate(
                                    `/oneproductdetail/${guid}/${shop.productGuid}`
                                );
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={`${api}/v1/image/download/${shop.photo}`}
                                    alt={shop.displayName}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        {shop.displayName}
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        KS: {shop.priceSell * shop.rate}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
