import React, { useEffect, useState } from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    useMediaQuery,
    Box,
    Button
} from "@mui/material";
import { getAllShopCategory } from "../../apicalls";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useAuth } from "../../DetailProvider";

const Category = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const view = " >";
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isLaptopScreen = useMediaQuery(theme =>
        theme.breakpoints.between("md", "lg")
    );
    const {translate} = useAuth()

    useEffect(() => {
        (async () => {
            const result = await getAllShopCategory();
            setCategories(result.data.items);
            setLoading(false);
            // console.log(result);
        })();
    }, []);

    return (
        <Box>
            <Box
                sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}
            >
                <Box>
                    <Typography variant="h4" sx={{ pb: 2 }}>
                        {translate.Category}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant="text"
                        size="medium"
                        onClick={() => navigate("/allcategories")}
                    >
                       {translate.ViewAll}{view}
                    </Button>
                </Box>
            </Box>

            <Slider
                {...settings}
                slidesToShow={isSmallScreen ? 2 : isLaptopScreen ? 3 : 6} // Adjust the number of slides to show based on screen size
            >
                {categories.map(category => (
                    <Card
                        key={category.guid}
                        sx={{
                            // maxWidth: isSmallScreen
                            //     ? 130
                            //     : isLaptopScreen
                            //     ? 150
                            //     : 200, // Adjust card width based on screen size
                            borderRadius: "15px",
                            m: 1, // Add margin to create spacing between cards
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            navigate(`/viewproductcategory/${category.guid}`)
                        }
                    >
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt={category.name}
                                width="100"
                                height="140"
                                
                                image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${category.photo}`}
                                sx={{
                                    objectFit: "cover", // Ensure the image covers the entire space
                                    height: isSmallScreen
                                        ? 80
                                        : isLaptopScreen
                                        ? 100
                                        : 140, // Adjust image height based on screen size
                                }}
                            />
                            <CardContent sx={{height:"80px"}}>
                                <Typography
                                    gutterBottom
                                    // variant={isSmallScreen ? "subtitle1" : "h6"} // Adjust typography variant based on screen size
                                    sx={{fontSize:"15px"}}
                                    component="div"
                                >
                                    {category.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Slider>
        </Box>
    );
};

export default Category;
