import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getProductReviews } from "../../apicalls";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function ViewReviews({ pguid }) {
    const [reviews, setReviews] = useState([]);

    console.log(pguid);
    useEffect(() => {
        (async () => {
            const result = await getProductReviews(pguid);
            setReviews(result.data.items);
        })();
    }, []);
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold" }} variant="h4">
                Reviews
            </Typography>

            <Box>
                <Card>
                    {reviews.map(review => {
                        return (
                            <Box key={review.guid}>
                                <CardContent>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            sx={{ mr: 1 }}
                                        >
                                            {review.name}
                                        </Typography>
                                        <Typography
                                            sx={{ mb: 1.5 }}
                                            color="text.secondary"
                                        >
                                            {moment(review.createdOn).format(
                                                "DD/MM/YYYY h:mm:ss a"
                                            )}
                                        </Typography>
                                    </Box>

                                    <Typography variant="h6">
                                        review comment -{" "}
                                        <b>{review.description}</b>
                                    </Typography>
                                </CardContent>
                                <Divider sx={{ m: 1 }} />
                            </Box>
                        );
                    })}
                </Card>
            </Box>
        </Box>
    );
}
