import React from "react";
import {
    Box,
    Button,
    Card,
    CardActionArea, CardMedia, useMediaQuery,
    useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Coupons() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleCardClick = () => {
        navigate("/viewCoupon");
    };

    return (
        <Box>
            <Card
                sx={{
                    height: isMobile ? "100%" : "200px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={handleCardClick}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height={isMobile ? "100%" : "284px"}
                        image="https://www.typecalendar.com/wp-content/uploads/2023/03/Coupon.jpg"
                        alt="green iguana"
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize: isMobile ? "14px" : "18px",
                                padding: isMobile ? "8px 16px" : "12px 24px",
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                opacity: 0.8,
                            }}
                        >
                            View Coupon
                        </Button>
                    </Box>
                </CardActionArea>
            </Card>
        </Box>
    );
}
