import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { grey, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import { UploadProfile, getUserInfo } from "../../apicalls";

const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ViewMyAccount() {
    const [expanded, setExpanded] = React.useState(false);
    const token = localStorage.getItem("access_token");
    const [userInfo, setUserInfo] = React.useState({});
    const [photoBase, setPhotoBase] = React.useState("");
    const [photo, setPhoto] = React.useState("");

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        (async () => {
            const result = await getUserInfo(token);
            // console.log(result.data);
            setUserInfo(result.data);
            // console.log(result.data);
            localStorage.setItem("guid", result.data.guid);
        })();
    }, [token]);

    function convertToBase64(e) {
        // console.log(e);
        var reader = new FileReader();
        reader.readAsDataURL(e.target.file[0]);
        reader.onload = () => {
            // console.log(reader.result); // base64 string
        };
        reader.onerror = error => {
            console.log("error", error);
        };
    }

    const getFile = async () => {
        const [fileHandle] = await window.showOpenFilePicker({
            types: [
                {
                    description: "Images",
                    accept: {
                        "image/*": [".png", ".jpeg", ".jpg"],
                    },
                },
            ],
            excludeAcceptAllOption: true,
            multiple: false,
        });

        return await fileHandle.getFile();
    };

    const changePhoto = async e => {
        const file = await getFile();
        setPhoto(URL.createObjectURL(file));

        const fileName =
            file.type === "image/png" ? `photo-photo.png` : `photo-photo.jpg`;

        // Convert the file to a base64 string
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract base64 part
            // console.log(base64String); // Base64 string
            setPhotoBase(base64String);
            UploadProfile(base64String, userInfo.guid, token);
        };
        reader.readAsDataURL(file);
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <IconButton
                        onClick={changePhoto}
                        onChange={convertToBase64}
                        sx={{ mb: "20px" }}
                    >
                        <Avatar
                            src={
                                photo
                                    ? photo
                                    : `https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${userInfo.avatar}`
                            }
                            sx={{
                                // background: "#216082",
                                width: 128,
                                height: 128,
                                mb: 1,
                            }}
                        >
                            {/* p */}
                        </Avatar>
                    </IconButton>
                }
                title={<Typography variant="h4">{userInfo.name}</Typography>}
            />
            <Box sx={{ marginLeft: 3, marginBottom: 1, marginRight: 3 }}>
                <Typography variant="subtitle1" sx={{ color: "grey" }}>
                    Full Name
                </Typography>
                <Typography variant="h6">{userInfo.name}</Typography>
                <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
                <Typography variant="subtitle1" sx={{ color: "grey" }}>
                    {" "}
                    Phone Number{" "}
                </Typography>
                <Typography variant="h6">{userInfo.phone}</Typography>
                <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
                <Typography variant="subtitle1" sx={{ color: "grey" }}>
                    {" "}
                    Email{" "}
                </Typography>
                <Typography variant="h6">{userInfo.email}</Typography>
                <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
                <Typography variant="subtitle1" sx={{ color: "grey" }}>
                    {" "}
                    Address{" "}
                </Typography>
                <Typography variant="h6">{userInfo.address}</Typography>
                <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
            </Box>
        </Card>
    );
}
