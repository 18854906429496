import { createContext, useContext, useState } from "react";

const PaymentMethodContext = createContext();

const PaymentMethodProvider = ({ children }) => {
    const [selectedMethod, setSelectedMethod] = useState("");
    const [expanded, setExpanded] = useState(false);

    return (
        <PaymentMethodContext.Provider
            value={{ selectedMethod, setSelectedMethod, expanded, setExpanded }}
        >
            {children}
        </PaymentMethodContext.Provider>
    );
};

// custom hook
const usePaymentMethod = () => useContext(PaymentMethodContext);

export { usePaymentMethod, PaymentMethodProvider };
