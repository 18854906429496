export const api = "https://dev2.mtlexpress.com.mm/qq-delivery-backend";
// const default_host_name = "shop.mtlexpress.com.mm"

export async function getallshops() {
    const res = await fetch(`${api}/v1/pos/shop/listAllShop`);
    if (!res.ok) return false;

    const shops = await res.json();
    return shops;
}

export async function getallbrands() {
    const res = await fetch(`${api}/v1/pos/shop/listAllShopBrand`);
    if (!res.ok) return false;

    const brands = await res.json();
    return brands;
}

// v1/currency/listAll

export async function getSearchShops(keyword) {
    const apiUrl = `${api}/v1/pos/shop/listAllShop`;

    const url = new URL(apiUrl);
    url.searchParams.append("keyword", keyword);

    const res = await fetch(url.toString());

    if (!res.ok) return false;

    const shops = await res.json();
    return shops;
}

export async function getAllShopCategory() {
    const res = await fetch(`${api}/v1/pos/shop/listAllShopCategory`);
    if (!res.ok) return false;

    const categories = await res.json();
    return categories;
}

export async function getAllProducts(guid) {
    const res = await fetch(`${api}/v1/pos/product/listAllProducts/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

// export async function getProductDetails(guid) {
//     const res = await fetch(`${api}/v1/pos/product/listProductDetails/${guid}`);
//     if (!res.ok) return false;

//     const products = await res.json();
//     return products;
// }
// /v1/pos/shop/listShopProduct/
export async function getAllProductFromShop(guid) {
    const res = await fetch(`${api}/v1/pos/shop/listShopProduct/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getTopPickProducts(guid) {
    const res = await fetch(`${api}/v1/pos/shop/listShopProductRandom/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getMostPopularProducts(guid) {
    const res = await fetch(
        `${api}/v1/pos/shop/listShopProductBestSeller/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getNewArrivalProducts(guid) {
    const res = await fetch(
        `${api}/v1/pos/shop/listShopProductNewArrival/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getShopHeader(guid) {
    const res = await fetch(
        `${api}/v1/pos/shop/listShopProductQuantity/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getShopHeaderDomain(guid) {
    const res = await fetch(
        `${api}/v1/pos/shop/listShopProductQuantity/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

// export async function getShopHeader(guid) {
//     let apiUrl;

//     if (guid) {
//         apiUrl = `${api}/v1/pos/shop/listShopProductQuantity/${guid}`;
//     } else {
//         // read the address bar
//         apiUrl = `${api}/v1/pos/shop/listShopProductQuantity/${default_host_name}`;
//     }

//     const res = await fetch(apiUrl);

//     if (!res.ok) return false;

//     const products = await res.json();
//     return products;
// }

export async function getShopFromProductDetail(guid) {
    const res = await fetch(`${api}/v1/pos/product/listShopProduct/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}
// getShopFromDetail
export async function getAddOn(guid) {
    const res = await fetch(
        `${api}/v1/pos/product/listAllProductAddOn/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getAvaUser(username) {
    const res = await fetch(`${api}/v1/user/available/${username}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getCurrency(guid) {
    const res = await fetch(`${api}/v1/currency/listAll?shopGuid=${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}
export async function getShopFromDetail(guid) {
    const res = await fetch(`${api}/v1/pos/product/listShopProduct/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getAllStates() {
    const res = await fetch(`${api}/v1/state/list`);
    if (!res.ok) return false;
    const states = await res.json();
    return states;
}

export async function getAllTownships() {
    const res = await fetch(`${api}/v1/township/list`);
    if (!res.ok) return false;
    const townships = await res.json();
    return townships;
}

export async function getTownshipsWithState(stateGuid) {
    const res = await fetch(`${api}/v1/township/list?stateGuid=${stateGuid}`);
    if (!res.ok) return false;
    const townships = await res.json();
    return townships;
}

// export async function getAllStreets() {
//     const res = await fetch(`${api}/v1/street/list`);
//     if(!res.ok) return false;
//     const streets = await res.json();
//     return streets;
// }

// export async function getRegister(username, email, phone, password, confirmPassword, houseNo, street, township) {
//  // Replace with your actual API endpoint

// const requestBody = {
//     name: username,
//     avatar: "",
//     status: "active",
//     role: "Customer",
//     username: "", // Leave it blank as per the instructions
//     password: password,
//     selfRegister: true,
//     autoAssign: "", // Leave it empty as per the instructions
//     contact: {
//         name: username, // Using the same username as the name in contact
//         address: houseNo, // Using the houseNo from the body for address in contact
//         street: street, // Using the street from the body for street in contact
//         township: township, // Using the township from the body for township in contact
//         primaryPhone: phone, // Using the phone from the body for primaryPhone in contact
//         otherPhones: phone, // Using the same phone as otherPhones in contact
//         email: email, // Using the email from the body for email in contact
//         comment: "Hi", // Adding "Hi" as the comment in contact
//         addressType: "Customer", // Adding "Customer" as the addressType in contact
//         avatar: "", // Leave it blank as per the instructions
//         primaryContact: true, // Set to true for primaryContact in contact
//     },
// };

//     const res = await fetch(`${api}/v1/person/create`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({requestBody}),
//     });

//     if (res.status === 409) {
//         return 409;
//     }

//     if (res.ok) {
//         let result = await res.json();
//         localStorage.setItem("token", result.token);
//         return result;
//     }

//     return false;
// }

export async function getLogin(username, password) {
    const res = await fetch(`${api}/v1/user/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    });

    if (res.ok) {
        const result = await res.json();

        // Save the data in local storage
        localStorage.setItem("access_token", result.data.access_token);
        localStorage.setItem("token_type", result.data.token_type);
        localStorage.setItem("refresh_token", result.data.refresh_token);
        localStorage.setItem("expires_in", result.data.expires_in);

        return result;
    }

    return false;
}

export async function verifyAccount(token) {
    const res = await fetch(`${api}/v1/user/brief`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (res.ok) {
        const result = await res.json();
        return result;
    }
    return false;
}

export async function enterOTP(token, otpCode) {
    const res = await fetch(`${api}/v1/person/verify_account/${otpCode}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (res.ok) {
        return await res.json();
    }
    return false;
}

export async function resendOTP(phone) {
    const res = await fetch(
        `${api}/v1/person/generate_verification_code/${phone}`
    );
    if (res.ok) {
        return await res.json();
    }
}

export async function getRegister(
    username,
    email,
    phone,
    password,
    confirmPassword,
    houseNo,
    street,
    township
) {
    // Replace with your actual API endpoint

    const requestBody = {
        name: username,
        avatar: "",
        status: "Active",
        role: "Customer",
        username: phone,
        password: password,
        selfRegister: "true",
        contact: {
            address: houseNo,
            street: street,
            townshipGuid: township,
            primaryPhone: phone,
            otherPhones: null,
            email: email,
            addressType: "Customer",
            comment: "Hello",
            primaryContact: "true",
        },
    };

    const res = await fetch(`${api}/v1/person/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
    });
    console.log(requestBody);

    if (res.status === 409) {
        return 409;
    }

    if (res.ok) {
        let result = await res.json();
        return result;
    }

    return false;
}

export default async function deliPlan(
    shopGuid,
    townshipGuid,
    fragile,
    insurance
) {
    const reqData = {
        weight: 1.5,
        width: 1,
        height: 1,
        length: 1,
        // fragile,
        // insurance,
    };

    const res = await fetch(
        `${api}/v1/delivery/price_check/${shopGuid}?guid=${townshipGuid}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
        }
    );
    if (res.ok) {
        return await res.json();
    }
    return false;
}

const addonCalc = item => {
    var total = 0;
    if (item.addons) {
        item.addons.map(addon => {
            total += addon.value;
        });
    }

    return total;
};

export const createOrder = async (
    name,
    phone,
    email,
    address,
    state,
    township,
    street,
    note,
    total,
    deliveryCharges,
    cartData,
    token,
    selectedMethod,
    finalize,
    base64String,
    base64Wave,
    country,
    zip
    // prodGuid,
    // quantity,
    // prodName,
    // shopGuid
) => {
    let shopMap = {};
    cartData?.map(data => {
        if (shopMap[data.shopGuid]) {
            var dataArray = shopMap[data.shopGuid];
            dataArray.push(data);
        } else {
            let dataArray = [];
            dataArray.push(data);
            shopMap[data.shopGuid] = dataArray;
        }
        return data;
    });
    // console.log(total);

    let result = "";

    for (const [key, value] of Object.entries(shopMap)) {
        let itemArray = [];
        value?.map(data => {
            return itemArray.push({
                productGuid: data.guid,
                quantity: data.cartQty,
                productName: data.productName,
                price:
                    data.priceSell * (data.rate ? data.rate : 1) +
                    addonCalc(data),
                originalPrice: data.priceSell * (data.rate ? data.rate : 1),
                addons: data.addons,
            });
        });

        let reqBody = null;

        switch (selectedMethod) {
            case "KPay":
                reqBody = {
                    // totalAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    // paidAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    totalAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    paidAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    deliveryCharges: parseFloat(deliveryCharges),
                    discountType: "",
                    discountAmount: 0.0,
                    status: "Pending",
                    condition: "InStock",
                    shipment: "Delivery",
                    remark: note,
                    items: itemArray,
                    contact: {
                        name: name,
                        address: address,
                        street: street,
                        township: "TownShip",
                        // townshipGuid: "1f06a524-05f2-420a-ad87-fa812ffd5104",
                        townshipGuid: township,
                        primaryPhone: phone,
                        otherPhones: phone,
                        email: email,
                        contactType: "Dropoff",
                        addressType: "Customer",
                        country: "Country",
                        zipcode: zip,
                        countryGuid: country,
                    },
                    cod: "true",
                    validPayment: false,
                    orderAs: "OnlineCustomer",
                    shopGuid: key,
                    courierGuid: null,
                    paidWith: selectedMethod,
                    finalize,
                    kpay: base64String,
                };
                break;
            case "WavePay":
                reqBody = {
                    // totalAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    // paidAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    totalAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    paidAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    deliveryCharges: parseFloat(deliveryCharges),
                    discountType: "",
                    discountAmount: 0.0,
                    status: "Pending",
                    condition: "InStock",
                    shipment: "Delivery",
                    remark: note,
                    items: itemArray,
                    contact: {
                        name: name,
                        address: address,
                        street: street,
                        township: "TownShip",
                        // townshipGuid: "1f06a524-05f2-420a-ad87-fa812ffd5104",
                        townshipGuid: township,
                        primaryPhone: phone,
                        otherPhones: phone,
                        email: email,
                        contactType: "Dropoff",
                        addressType: "Customer",
                        country: "Country",
                        zipcode: zip,
                        countryGuid: country,
                    },
                    cod: "true",
                    validPayment: false,
                    orderAs: "OnlineCustomer",
                    shopGuid: key,
                    courierGuid: null,
                    paidWith: selectedMethod,
                    finalize,
                    wave: base64Wave,
                };
                break;
            default:
                reqBody = {
                    // totalAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    // paidAmount:
                    //     parseFloat(
                    //         itemArray[parseInt(itemArray.length) - 1].price *
                    //             itemArray[parseInt(itemArray.length) - 1]
                    //                 .quantity
                    //     ) +
                    //     parseInt(deliveryCharges) +
                    //     addonCalc(itemArray[parseInt(itemArray.length) - 1]),
                    totalAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    paidAmount:
                        parseFloat(
                            itemArray[parseInt(itemArray.length) - 1].price *
                                itemArray[parseInt(itemArray.length) - 1]
                                    .quantity
                        ) + parseFloat(deliveryCharges),
                    deliveryCharges: parseFloat(deliveryCharges),
                    discountType: "",
                    discountAmount: 0.0,
                    status: "Pending",
                    condition: "InStock",
                    shipment: "Delivery",
                    remark: note,
                    items: itemArray,
                    contact: {
                        name: name,
                        address: address,
                        street: street,
                        township: "TownShip",
                        // townshipGuid: "1f06a524-05f2-420a-ad87-fa812ffd5104",
                        townshipGuid: township,
                        primaryPhone: phone,
                        otherPhones: phone,
                        email: email,
                        contactType: "Dropoff",
                        addressType: "Customer",
                        country: "Country",
                        zipcode: zip,
                        countryGuid: country,
                    },
                    cod: "true",
                    validPayment: false,
                    orderAs: "OnlineCustomer",
                    shopGuid: key,
                    courierGuid: null,
                    paidWith: selectedMethod,
                    finalize,
                    KPay: base64String,
                };
        }

        const res = await fetch(`${api}/v1/pos/sales/?access=website`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqBody),
        });
        if (res.status === 409) {
            return 409;
        }

        if (res.ok) {
            // await res.json();
            result = await res.json();
            // return result;
        }
    }
    return result;

    // let shopList = [];
    // cartData?.map(data => {
    //     return shopList.push({
    //         shopGuid: data.store_guid,
    //     });
    // });

    // return false;
};

export async function getOrderDetailForSuccessfulPg(id) {
    const res = await fetch(`${api}/v1/pos/sales/detailsBySaleID/${id}`);
    if (!res.ok) return false;

    const details = await res.json();
    return details;
}

export async function getNewPassword(phone) {
    const res = await fetch(`${api}/v1/person/generate_password/${phone}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getDescription(guid) {
    const res = await fetch(
        `${api}/v1/pos/product/listProductDescription/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getProductVariantDetail(guid) {
    const res = await fetch(
        `${api}/v1/pos/product/productvariantdetail/${guid}`
    );
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}
export async function getUserInfo(token) {
    const res = await fetch(`${api}/v1/user/brief`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    if (!res.ok) return false;

    const userInfo = await res.json();
    return userInfo;
}

export async function getOrderHistory(guid) {
    const reqBody = {
        buyerGuid: guid,
        first: 0,
        max: 10,
    };
    const res = await fetch(`${api}/v1/pos/sales/listSalesOrder`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    });

    if (!res.ok) return false;

    const orderHistory = await res.json();
    return orderHistory;
}

export async function getOrderDetail(guid) {
    const res = await fetch(`${api}/v1/pos/sales/product_details/${guid}`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}
export async function getRefreshToken(retoken) {
    const res = await fetch(`${api}/v1/pos/sales/listSalesOrder`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(retoken),
    });
    const orderHistory = await res.json();
    return orderHistory;
}

export async function getJustForHome() {
    const res = await fetch(`${api}/v1/pos/product/listAllProductJust`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getTopRate() {
    const res = await fetch(`${api}/v1/pos/product/listTopRatedProduct`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getNewArrival() {
    const res = await fetch(`${api}/v1/pos/product/listAllProductNewArrival`);
    if (!res.ok) return false;

    const products = await res.json();
    return products;
}

export async function getShippingContact(token) {
    const res = await fetch(`${api}/v1/pos/sales/shipping_contact_history`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!res) return false;
    const contact = await res.json();
    return contact.data.items[0];
}

export async function getAllShippingContacts(token) {
    const res = await fetch(`${api}/v1/pos/sales/shipping_contact_history`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!res) return false;
    const contact = await res.json();
    return contact.data.items;
}

export async function postOrderProductReview(pguid, uguid, review) {
    const res = await fetch(`${api}/v1/pos/comment/createComment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            productGuid: pguid,
            personGuid: uguid,
            description: review,
        }),
    });
    if (!res.ok) return false;

    const description = await res.json();
    return description;
}
// {{base_url}}/v1/pos/comment/e73cde77-4b19-4065-a060-9972fea08da8
export async function getProductReviews(guid) {
    const res = await fetch(`${api}/v1/pos/comment/${guid}`);
    if (!res.ok) return false;

    const comments = await res.json();
    return comments;
}

///v1/person/update

export async function UploadProfile(avatar, guid, token) {
    const requestBody = {
        avatar,
        guid,
    };

    const res = await fetch(`${api}/v1/person/update`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
    });
    console.log(requestBody);

    if (res.status === 409) {
        return 409;
    }

    if (res.ok) {
        let result = await res.json();
        return result;
    }

    return false;
}

// /v1/pos/adv/
export async function getCarouselPhoto() {
    const res = await fetch(`${api}/v1/pos/adv/`);
    if (!res.ok) return false;

    const carousels = await res.json();
    return carousels;
}

export async function getAllCountries() {
    const res = await fetch(`${api}/v1/currency/country`);
    if (!res.ok) return false;
    const countries = await res.json();
    return countries;
}
