import { Box } from "@mui/material";
import ShopFilterBy from "./ShopFilterBy";
import AllProductFilter from "./AllProductFilter";

export default function ShopAllProducts() {
    return (
        <Box sx={{ display: "flex", }}>
            <Box>
                {/* <ShopFilterBy /> */}
            </Box>
            {/* <Box sx={{ marginLeft: "20px" }}> */}
            <Box>
                <AllProductFilter />
            </Box>
        </Box>
    );
}
