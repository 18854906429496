import { Box, Card, Grid, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Summary from "../Components/ShoppingCart/Summary";
import ShoppingCartCard from "../Components/ShoppingCart/ShoppingCartCard";
import { useCart } from "../Contexts/CartContext";
import { useAuth } from "../DetailProvider";
import { getCurrency } from "../apicalls";

const ShoppingCart = () => {
    const { cart, setCart } = useCart();
    const { cartLength, setCartLength } = useCart();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
    });

    const { detailInfo, setDetailInfo } = useAuth();
    const [proDetails, setProDetails] = useState([]);
    const [proInfo, setProInfo] = useState(null);

    useEffect(() => {
        setProInfo(
            proDetails.filter(proDetail => proDetail.guid === detailInfo)[0]
        );
    }, [detailInfo, proDetails]);

    // const handleSelectAllChange = event => {
    //     const { checked } = event.target;
    //     setSelectAll(checked);
    //     setCheckboxes({
    //         checkbox1: checked,
    //         checkbox2: checked,
    //         checkbox3: checked,
    //     });
    // };

    const handleCheckboxChange = event => {
        const { name, checked } = event.target;
        setCheckboxes(prevCheckboxes => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
        setSelectAll(Object.values(checkboxes).every(value => value === true));
    };

    const [useCurrency, setUseCurrency] = useState({});

    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result?.data?.item[0]);
        })();
    }, []);

    return (
        !loading && (
            <Box sx={{ padding: "10px 50px 20px 50px" }}>
                <Grid container sx={{ pt: 4 }} spacing={1}>
                    <Grid item xs={9}>
                        <Box>
                            <Card sx={{ marginBottom: "10px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "24px",
                                        gap: "24px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Shopping Cart ({cart ? cartLength : 0})
                                    </Typography>

                                    {/* <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormControlLabel
                                            sx={{ gap: "16px" }}
                                            control={
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={
                                                        handleSelectAllChange
                                                    }
                                                />
                                            }
                                            label="Select All"
                                        />
                                        <IconButton>
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </Box> */}
                                    {/* Clear Cart Btn */}
                                    {/* <Button
                                        onClick={() => {
                                            localStorage.removeItem("cart");
                                            window.location.reload();
                                        }}
                                    >
                                        <DeleteIcon color="primary" />
                                        <Typography>Clear Cart</Typography>
                                    </Button> */}
                                </Box>
                            </Card>
                            {cart?.map(
                                product =>
                                    product && (
                                        <ShoppingCartCard
                                            checkboxes={checkboxes}
                                            handleCheckboxChange={
                                                handleCheckboxChange
                                            }
                                            product={product}
                                            key={product.guid}
                                        />
                                    )
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={3}>
                        <Summary proInfo={proInfo} useCurrency={useCurrency} />
                    </Grid>
                </Grid>
                {cart.length === 0 ? (
                    <Typography
                        sx={{
                            bgcolor: "red",
                            p: 2,
                            maxWidth: "250px",
                            color: "white",
                            borderRadius: "20px",
                        }}
                    >
                        There is no selected product
                    </Typography>
                ) : null}
            </Box>
        )
    );
};

export default ShoppingCart;
