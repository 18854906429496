import { Box, Divider } from "@mui/material";
import ShopsUpper from "../Components/SingleShop/ShopsUpper";
import TabsForShop from "../Components/SingleShop/TabsForShop";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../DetailProvider";

export default function ViewOneShop() {
    const {sguid} = useParams();
    
    
    useEffect(()=>{
        console.log(sguid);
    },[sguid]);
    return (
        <Box>
            <ShopsUpper
                sx={{
                    paddingLeft: "72px",
                    paddingTop: "20px",
                    paddingRight: "72px",
                }}
            />
            <Divider />
            <TabsForShop
                sx={{
                    paddingLeft: "72px",
                    paddingTop: "20px",
                    paddingRight: "72px",
                }}
            />
        </Box>
    );
}
