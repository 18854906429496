import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Paper,
    Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CheckoutFileUpload from "./CheckoutFileUpload";
import { usePaymentMethod } from "../../Contexts/PaymentMethodContext";
import CheckoutFileUploadWave from "./CheckoutFileUploadWave";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const CheckoutPaymentMethod = ({
    handleFileUpload,
    handleFileUploadWave,
    base64String,
    base64Wave,
    selectedFile,
    selectedFileWave,
}) => {
    // const [expanded, setExpanded] = React.useState(false);
    const { selectedMethod, setSelectedMethod, expanded, setExpanded } =
        usePaymentMethod();

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handlePaymentMethodSelect = (method, panel) => {
        setSelectedMethod(method);
        setExpanded(panel);
        console.log(method, panel);
    };

    const handlePaymentMethodSelectWave = (method, panel) => {
        setSelectedMethod(method);
        setExpanded(panel);
        console.log(method, panel);
    };

    const fileInputRef = useRef();
    const fileInputRefWave = useRef();

    return (
        <Card sx={{ maxWidth: "full" }} elevation={1}>
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{ padding: "16px", fontWeight: "bold" }}
                >
                    Payment Method
                </Typography>
                <form>
                    <Card
                        sx={{
                            maxWidth: "full",
                            border:
                                selectedMethod === "Cash On Delivery"
                                    ? "1px solid red"
                                    : "",
                        }}
                        elevation={1}
                        onClick={() => {
                            handlePaymentMethodSelect("Cash On Delivery");
                            setExpanded(false);
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    alignSelf: "flex-start",
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    alt="COD"
                                    src="/images/cod.png"
                                    sx={{ width: 40, height: 40 }}
                                />
                                <Typography
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1 0 0",
                                    }}
                                >
                                    Cash On Delivery
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Accordion
                        key={1}
                        sx={{
                            borderRadius: "5px",
                            border:
                                selectedMethod === "KPay"
                                    ? "1px solid red"
                                    : "",
                        }}
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        onClick={() => {
                            handlePaymentMethodSelect("KPay", "panel1");
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "16px",
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    alt="KPay"
                                    src="/images/Kpay.png"
                                    sx={{ width: 40, height: 40 }}
                                />
                                <Typography fullWidth sx={{ flexShrink: 0 }}>
                                    KPay
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "16px 24px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Typography>Account Name : </Typography>
                                <Typography>Account No : </Typography>
                                <Typography color={"secondary"}>
                                    Lorem ipsum dolor sit amet consectetur.
                                    Imperdiet eu dignissim quam id hac sagittis.
                                    Ultrices consectetur velit cursus porttitor
                                    phasellus amet velit.
                                </Typography>
                                <label
                                    htmlFor="file-upload-kpay"
                                    style={{ cursor: "pointer" }}
                                >
                                    <input
                                        id="file-upload-kpay"
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileUpload}
                                    />
                                    {selectedFile && (
                                        <img
                                            width={200}
                                            height={300}
                                            src={URL.createObjectURL(
                                                selectedFile
                                            )}
                                            alt="Selected"
                                        />
                                    )}
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            display: "flex",
                                            padding: "16px 56px",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                            gap: "10px",
                                            border: "1px dashed black",
                                        }}
                                    >
                                        <AddPhotoAlternateOutlinedIcon />
                                        <Typography variant="body1">
                                            Upload Image
                                        </Typography>
                                    </Paper>
                                </label>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        key={2}
                        sx={{
                            borderRadius: "5px",
                            border:
                                selectedMethod === "WavePay"
                                    ? "1px solid red"
                                    : "",
                        }}
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        onClick={() =>
                            handlePaymentMethodSelectWave("WavePay", "panel2")
                        }
                    >
                        <AccordionSummary
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "16px",
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    alt="Wave Pay"
                                    src="/images/WavePay.png"
                                    sx={{ width: 40, height: 40 }}
                                />
                                <Typography fullWidth sx={{ flexShrink: 0 }}>
                                    Wave Pay
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "16px 24px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Typography>Account Name : </Typography>
                                <Typography>Account No : </Typography>
                                <Typography color={"secondary"}>
                                    Lorem ipsum dolor sit amet consectetur.
                                    Imperdiet eu dignissim quam id hac sagittis.
                                    Ultrices consectetur velit cursus porttitor
                                    phasellus amet velit.
                                </Typography>
                                <label
                                    htmlFor="file-upload-wavepay"
                                    style={{ cursor: "pointer" }}
                                >
                                    <input
                                        id="file-upload-wavepay"
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRefWave}
                                        style={{ display: "none" }}
                                        onChange={handleFileUploadWave}
                                    />
                                    {selectedFileWave && (
                                        <img
                                            width={200}
                                            height={300}
                                            src={URL.createObjectURL(
                                                selectedFileWave
                                            )}
                                            alt="Selected"
                                        />
                                    )}
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            display: "flex",
                                            padding: "16px 56px",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                            gap: "10px",
                                            border: "1px dashed black",
                                        }}
                                    >
                                        <AddPhotoAlternateOutlinedIcon />
                                        <Typography variant="body1">
                                            Upload Image
                                        </Typography>
                                    </Paper>
                                </label>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </form>
            </CardContent>
        </Card>
    );
};

export default CheckoutPaymentMethod;
