import {
    Avatar,
    Box,
    Button,
    Card,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { enterOTP, resendOTP } from "../../apicalls";
import { useNavigate } from "react-router-dom";

const OTPForm = () => {
    const otpRef = useRef();
    const navigate = useNavigate();
    return (
        <Box sx={{ background: "red", height: "100vh" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    height: 110,
                }}
            >
                <Avatar sx={{ width: 98, height: 98, mb: -5 }}>A</Avatar>
            </Box>
            <Box
                sx={{
                    mx: {
                        xs: 2, // 2 units of margin on extra-small screens (mobile)
                        sm: 4, // 4 units of margin on small screens (tablet)
                        md: 5, // 5 units of margin on medium screens
                        lg: 40, // 40 units of margin on large screens (laptop and above)
                    },
                }}
                align="center"
            >
                <Card
                    sx={{
                        width: {
                            xs: "100%", // Full width on extra-small screens (mobile)
                            sm: "100%", // 80% width on small screens (tablet)
                            md: "60%", // 60% width on medium screens
                            lg: "433px", // Fixed width of 433px on large screens (laptop and above)
                        },
                        paddingX: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingBottom: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingTop: {
                            xs: 1, // 1 unit of padding on extra-small screens (mobile)
                            sm: 2, // 2 units of padding on small screens (tablet)
                            md: 3, // 3 units of padding on medium screens
                            lg: 3, // 3 units of padding on large screens (laptop and above)
                        },
                    }}
                    elevation={5}
                >
                    <Typography variant="h5" sx={{ my: 3 }} align="center">
                        Verify Account
                    </Typography>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            const token = localStorage.getItem("access_token");
                            enterOTP(token, otpRef.current.value);
                            navigate("/");
                        }}
                    >
                        <OutlinedInput
                            placeholder="OTP Code"
                            fullWidth
                            sx={{ mb: 2 }}
                            inputRef={otpRef}
                        />
                        <Button
                            sx={{ m: 2 }}
                            align="end"
                            onClick={() => {
                                const userData = JSON.parse(
                                    localStorage.getItem("authUser")
                                );
                                // console.log(userData.username)
                                (async () => {
                                    await resendOTP(userData.username);
                                })();
                            }}
                        >
                            Resend Code
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ color: "primary" }}
                        >
                            Submit
                        </Button>
                    </form>
                </Card>
            </Box>
        </Box>
    );
};

export default OTPForm;
