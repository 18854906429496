import { colors, createTheme, ThemeProvider } from "@mui/material";
import React from "react";

const ThemedApp = ({ children }) => {
    const white = "#FFFFFF";
    const black = "#000000";
    const themeLight = "rgba(242, 0, 2, 1)";
    const themeMain = "rgba(242, 0, 2, 1)";
    const themeDark = "rgba(242, 0, 2, 1)";

    const theme = createTheme({
        white,
        black,
        palette: {
            primary: {
                contrastText: white,
                light: themeLight,
                main: themeMain,
                dark: themeDark,
            },
            secondary: {
                contrastText: white,
                dark: colors.blueGrey[900],
                main: colors.blueGrey[600],
                light: colors.blueGrey[400],
            },
            success: {
                contrastText: white,
                dark: themeDark,
                main: themeMain,
                light: themeLight,
            },
            info: {
                contrastText: white,
                dark: colors.blue[900],
                main: colors.blue[600],
                light: colors.blue[400],
            },
            warning: {
                contrastText: white,
                dark: colors.orange[900],
                main: colors.orange[600],
                light: colors.orange[400],
            },
            error: {
                contrastText: white,
                dark: colors.red[900],
                main: colors.red[600],
                light: colors.red[400],
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
                link: colors.blue[600],
            },
            background: {
                default: "#F4F6F8",
                paper: white,
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemedApp;
