import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const LocalDeliveryMethod = ({ open, handleCloseModal, product, township }) => {
    // const [deliName, setDeliName] = useState("");
    // const [deliPrice, setDeliPrice] = useState(0);
    const [id, setId] = useState(1);

    useEffect(() => {
        setId(
            localStorage.getItem("deliPlan")
                ? parseInt(JSON.parse(localStorage.getItem("deliPlan")))
                : 1
        );
        localStorage.setItem("selectedDeliPlan", 1);
    }, [township]);

    const deliPlans = JSON.parse(localStorage.getItem("deliPlan"));
    // useEffect(() => {
    //     deliPlans?.map(deli => {
    //         setDeliName(deli.plan);
    //         setDeliPrice(deli.totalPrice);
    //         return deli;
    //     });
    // }, [deliPlans]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Delivery Methods
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "100%" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Standard Delivery" />
                        <Tab label="Delivery to Highway Gate" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24px",
                            }}
                        >
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="delivery"
                                    name="radio-buttons-group"
                                    value={id}
                                    onChange={e => {
                                        setId(e.target.value);
                                        localStorage.setItem(
                                            "selectedDeliPlan",
                                            e.target.value
                                        );
                                    }}
                                >
                                    {deliPlans?.map(plan => (
                                        <FormControlLabel
                                            key={plan.id}
                                            value={plan.id}
                                            control={<Radio />}
                                            label={
                                                <Box
                                                    sx={{
                                                        marginLeft: "16px",
                                                    }}
                                                >
                                                    <Typography>
                                                        {plan.plan}
                                                    </Typography>
                                                    <Typography color="secondary">
                                                        {plan.totalPrice} Ks
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24px",
                            }}
                        >
                            {/*  */}
                        </Box>
                    </TabPanel>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const TabPanel = ({ children, value, index }) => {
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={2}>{children}</Box>}
        </Box>
    );
};

export default LocalDeliveryMethod;
