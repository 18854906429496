import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getallshops } from "../../apicalls";
import "../../text.css";
import { useAuth } from "../../DetailProvider";

export default function Shop({ loading, shops, shopstitle, showView }) {
    const { translate, setTranslate } = useAuth();
    const navigate = useNavigate();
    const view = " >";

    return (
        !loading && (
            <Box sx={{ p: "50px 0px 0px 0px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant="h4" sx={{ pb: 2 }}>
                            {shopstitle}
                        </Typography>
                    </Box>
                    <Box>
                        {showView && (
                            <Button
                                variant="text"
                                size="medium"
                                onClick={() => navigate("/allshops")}
                            >
                                {translate.ViewAll} {view}
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} xs={12}>
                            {shops.slice(0, 6).map(shop => (
                                <Grid item xs={6} sm={4} md={2} key={shop.guid}>
                                    <Card
                                        sx={{ maxWidth: 200 }}
                                        onClick={() =>
                                            navigate(
                                                `/view-one-shop/${shop.guid}`
                                            )
                                        }
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                width="140"
                                                sx={{objectFit:"cover"}}
                                                image={`http://mtlexpress.com.mm:8070/qq-delivery-backend/v1/image/download/${shop.logo}`}
                                                alt="green iguana"
                                            />
                                            <CardContent
                                                sx={{
                                                    textAlign: "center",
                                                    mb: -2,
                                                }}
                                            >
                                                <Divider />
                                                <Typography
                                                    // variant="h5"
                                                    sx={{fontSize:"20px"}}
                                                    className="shopNameText"
                                                >
                                                    {shop.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={1}
                            xs={12}
                            sx={{ marginTop: "20px" }}
                        >
                            {shops.slice(6, 12).map(shop => (
                                <Grid item xs={6} sm={4} md={2} key={shop.guid}>
                                    <Card
                                        sx={{ maxWidth: 200 }}
                                        onClick={() =>
                                            navigate(
                                                `/view-one-shop/${shop.guid}`
                                            )
                                        }
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shop.logo}`}
                                                alt="green iguana"
                                            />
                                            <CardContent
                                                sx={{
                                                    textAlign: "center",
                                                    mb: -2,
                                                }}
                                            >
                                                <Divider />
                                                <Typography
                                                    // variant="h5"
                                                    sx={{fontSize:"20px"}}
                                                    className="shopNameText"
                                                >
                                                    {shop.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    );
}
