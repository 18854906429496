import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import React from "react";

const CheckoutAdditionNotes = ({ note, setNote, noteChange }) => {
    return (
        <>
            <Box>
                <Card sx={{ maxWidth: "full" }} elevation={1}>
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold" }}
                        >
                            Additional notes (optional)
                        </Typography>
                        <TextField
                            label="Add notes about your order"
                            type="text"
                            value={note}
                            onChange={noteChange}
                            sx={{ width: "100%" }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default CheckoutAdditionNotes;
